﻿.ArticleWithBackground > a {
    width: 100%;
    height: 100%;
    & span.inner-content {
        display: inline-block;
        height: 100%;

        & div.row {
            height: 100%;
        }

        & .article-overlay {
            height: 100%;
        }
    }
}

.ArticleWithBackground {
    & .inner-content {
        & .article-overlay {
            & h1 {
                color: white;
            }
        }
    }
}