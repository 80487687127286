﻿/* BlogArticle CSS
-------------------------------------------------------------------------------------*/
.d4-article {
    max-width: 100%;
    width: 100%;

    .glyphicon.glyphicon-time {
        margin-right: 0.5em;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .d4-ingress {
        margin-bottom: 0.5em;
    }

    & .d4-article-body {
        max-height: initial;
        overflow: visible;
        position: relative;

        &.closed::before {
            width: 100%;
            content: " ";
            height: 45px;
            position: absolute;
            text-align: center;
            line-height: 4;
            left: 0;
            bottom: 0px;
            font-size: 14px;
            background: linear-gradient(hsla(0,0%,100%,0),#fff 25px);
        }

        & .readmore {
            position: absolute;
            bottom: 0px;
            cursor: pointer;
            z-index:100;
        }

        & .show-link {
            display: none;
        }

        & .hide-link {
            display: block;
            position: relative;
        }
    }

    & .image-container {
        margin: 20px;

        & .image-heading {
            display: block;
            font-style: italic;
            color: #808080;
        }
    }
}
.d4-article .d4-article-body.closed {
    max-height: 16px;
    overflow: hidden;
    position: relative;

    & .readmore {
        position: absolute;
        top: 0px;
        cursor:pointer;
        left: 0px;
    }
    & .show-link{
        display:block;
    }
    & .hide-link{
        display:none;
    }

    & ::before {
        width: 100%;
        content: " ";
        height: 100px;
        position: absolute;
        text-align: center;
        line-height: 4;
        left: 0;
        top: 0px;
        font-size: 14px;
        background: -webkit-linear-gradient(hsla(0,0%,100%,0),#fff 70px);
        /*background: linear-gradient(hsla(0,0%,100%,0),hsla(0,0%,100%,0.5) 70px);*/
            background:linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.3));
    }
}



.d4-article.blog-standard {
    position: relative;

    &::before {
        content: '';
        background: linear-gradient(to right,#fff 0%,#000 50%,#fff 100%);
        width: 60%;
        height: 1px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .d4-ingress {
        font-size: 16px;
        margin-bottom: 50px;

        .image-container {
            float: right;
            margin-left: 50px;
            margin-right: 0px;
            margin-top: 0px;
            margin-bottom: 50px;
        }
    }

    .d4-article-body {
        clear: both;

        .image-container {
            float: left;
            margin-left: 0px;
            margin-top: 0px;
            margin-right: 50px;
            margin-bottom: 50px;
        }
    }
}

body.device-size-xs .d4-article.blog-standard {
    & .d4-ingress .image-container {
        margin-left: 0px;
        margin-bottom: 40px;
    }
}

body.device-size-xs .d4-article .StdArticleBox {
    & .image-container {
        margin-left: 0px;
        margin-bottom: 40px;
        margin-right:0px;
    }
}

#PageColumnLeft .d4-article{
    margin-left:10px;

    & .StdArticleBox .image-container{
        margin-left:0px;
        margin-right:0px;
    }
}

#PageColumnLeft .d4-article.blog-standard {
    

    & .d4-ingress .image-container {
        margin-left: 0px;
    }
}

    .d4-article.blog-center {
        position: relative;

        &::before {
            content: '';
            background: linear-gradient(to right,#fff 0%,#000 50%,#fff 100%);
            width: 60%;
            height: 1px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .d4-blogarticle-title {
            text-align: center;
        }

        .d4-ingress {
            font-weight: bold;
            text-align: center;
            margin-top: 1em;
            margin-bottom: 50px;

            .image-container {
                float: left;
                margin-left: 0px;
                margin-top: 0px;
                margin-right: 50px;
                margin-bottom: 50px;
            }
        }

        .d4-blogarticle-published {
            text-align: center;
            font-style: italic;
        }

        .d4-article-body {
            clear: both;

            .image-container {
                float: right;
                margin-left: 50px;
                margin-top: 0px;
                margin-right: 0px;
                margin-bottom: 50px;
            }
        }
    }

    .d4-article.blog-with-imageheader {
        position: relative;
        margin-bottom: 4em;

        &::before {
            content: '';
            background: linear-gradient(to right,#fff 0%,#000 50%,#fff 100%);
            width: 60%;
            height: 1px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .StdArticleBox {
            margin-top: 6em;

            > .image-container {
                box-shadow: 2px 2px 4px rgba(0,0,60,0.4);
                text-align: center;
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        .d4-ingress {
            margin-bottom: 50px;
        }

        .d4-blogarticle-published-date {
            color: #72afd2;
        }

        .d4-article-body {
            clear: both;

            .image-container {
                float: left;
                margin-left: 0px;
                margin-top: 0px;
                margin-right: 50px;
                margin-bottom: 50px;
            }
        }
    }