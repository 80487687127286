
                    @import "@/css/ajax-rendering/admin-lte/_variables.scss";
                
@import '../spinthatshit/loaders';
.field-paging-container
{
    width:100%;
    text-align:center;
}
a.field-paging-next {
    width: 46px;
    height: 46px;
    display: block;
}
div.field-paging-next {
    width: 46px;
    height: 46px;
}
.field-paging-get-next {
    width: 46px;
    height: 46px;
    border: 3px solid grey;
    border-radius: 50%;
    position: relative;
    display:block;    
}
.FieldPaging a.field-paging-next {
    background-color:initial;
}

.field-paging-down-arrow {
    display: block;
    padding-top: 3px;
}
.field-paging-down-arrow::after {
    content: "\e259";
    font-size: 28px;
    font-family: 'Glyphicons Halflings';
    text-shadow: 0px -2px rgba(0,0,0,0.3);
}
.field-paging-down-arrow:hover::after {
    text-shadow: initial;
}

.FieldPaging .field-paging-container a.field-paging-text {
    display: block;
    width: auto;
}
.field-paging-get-next.hidden {
    display: none;
}

.field-paging-loading {
    @include loader05;
}

.field-paging-loading.hidden {
    display:none;
}
.FieldPaging .field-paging-container a:hover {
    text-shadow: 5px 5px 3px 3px rgba(0,0,0,0.5);
    background: transparent;
    color:black;
}

.FieldPaging .field-paging-container a {
    color: black;
    margin: 0 auto;
    background: transparent;
    width: 46px;
    height: 46px;
}


.paging-center {
    margin: auto;    
}
.paging-block{
    text-align:left;
}

.web-pub-field .ajax-field i.icon-spinner {
    width: 20px;
    animation: 750ms fadeInSudden;
    animation-fill-mode: forwards;
    display: none;  
}

.web-pub-field .ajax-field i.icon-spinner:before {
    animation: spin 2s infinite linear;
}

.web-pub-field .ajax-field[data-listing-type='1'] i.icon-spinner {
    display: inline-block;
    width: 40px;
    margin:auto;
}
.web-pub-field .ajax-field[data-listing-type='1'] i.icon-spinner:before {
    font-size: 40px;
}

@keyframes fadeInSudden {
    99% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}