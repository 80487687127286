﻿@import '../../publishing/mixins';

#center-popup.d4-popup-quickview div {
    /*overflow: initial;*/
    & .rsOverflow {
        overflow: hidden;
    }

    .rsDefault .rsBullets {
        line-height: 24px;
    }
}

.d4-preview {
    border: none;
    width: 100%;

    .royalSlider {
        @include fromSizeSM() {
            height: 100%;
        }
    }

    .variant-matrix-button {
        display: block;
        clear: both;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .d4-preview-item-info {
        .item-details.row {
            display: flex;
            flex-direction: column;
        }

        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .info-element {
            display: flex;
            flex-direction: column;
        }

        .PriceLabel {
            padding-left: 0px;
        }

        .section-container.info-element {
            flex: 1;
        }

        #d4-preview-collapser {
            height: 100%;
            min-height: 100%;

            .info-element.panel {
                max-height: calc(100% - 2em);

                .preview-section-header {
                }

                .collapse {
                    flex: 1;
                    overflow-y: auto;
                }
            }
        }
    }

    .section-container {
        .panel {
            box-shadow: none;
        }
    }

    .dropdown .dropdown-menu {
        max-height: 150px;
        overflow: auto;
    }

    .preview-section-header {
        text-transform: uppercase;
        padding-bottom: 2px;

        a {
            color: #333;
            text-decoration: none;
            display: block;

            :hover {
                text-decoration: none;
            }

            border-bottom: 1px #888 solid;
        }

        .glyphicon {
            float: right;
            font-size: 10px;
            line-height: 16px;
        }

        .glyphicon-plus {
            display: none;
        }

        .glyphicon-minus {
            display: block;
        }

        .collapsed {
            .glyphicon-plus {
                display: block;
            }

            .glyphicon-minus {
                display: none;
            }
        }
    }
}

.d4-preview-container {
    width: 100%;
    text-align: left;

    @include fromSizeSM() {
        /*display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 100%;
        grid-gap: 20px;*/
        display: flex;
        flex-direction: row;
        padding: 8px;
        height: 100%;
        min-height: 100%;

        .d4-preview-item-image,
        .d4-preview-item-info {
            max-width: calc(50% - 10px);
            width: calc(50% - 10px);
        }

        .d4-preview-item-image {
            margin-right: 10px;
        }

        .d4-preview-item-info {
            margin-left: 10px;
        }
    }
}

.d4-preview-item {
    margin: 4px 0;
}

.d4-preview-item-image {
    margin-right: 0px;
}

.d4-preview-item-technicalinfo {
    padding-right: 7px;

    .technical-info-header {
        display: block;
        font-size: 18px;
    }
}

.d4-preview-item-info {
    /*@include fromSizeSM() {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        justify-content: stretch;
        align-content: start;
    }*/
}

#center-popup.d4-popup-quickview div.section-container {
    overflow-y: hidden;
}

.d4-preview-item-heading {

    .AdHeader1 {
        font-size: 20px;
        color: #434345;
        font-weight: bold;
        display: block;
    }

    .AdHeader2 {
        font-size: 16px;
        color: #c0c0c0;
    }
}

.d4-preview-item-prodnumber {

    .product-number-inner, .alt-product-number {
        display: inline-block;
        padding: 0px 10px 0px 0px;
    }

    .prd-num-label {
        color: #a8a8a8;
        font-size: 10px;
    }
}

.d4-preview-item-score {
}

.d4-preview-item-price {
    span.show,
    .RRP,
    .PriceLabel,
    .OldPriceLabel {
        display: inline-block !important;
    }

    .PriceLabel {
        color: #f26652;
        font-size: 28px;
    }

    .OldPriceLabel {
        color: #c0c0c0;
        font-size: 20px;
    }

    .RRP {
    }
}


.d4-preview-item-description {
    margin-right: 20px
}

.d4-preview-container > div.d4-preview-item-description {
    font-size: 13px;
    height: 100%;
    max-height: 325px;
    overflow-y: auto;
    overflow-x: hidden;
}

.d4-preview-item-purchase-button {

    .ad-buy-button {
        width: 50%;
        padding: 1px 1px;
        font-size: 24px;
        font-weight: 300;
    }
}

.d4-preview-item-stock {

    .AddStockLabel {
        display: initial;
    }
}

.d4-preview-container .ad-buy-button,
.d4-preview-container .favorite-button {
    display: inline-block !important;
}


.d4-preview-container .ad-buy-button {
    background: #434345;
    border-radius: 3px;
    margin-right: 10px;
    color: white;
    width: calc(100% - 50px);
}

.d4-preview-container {
    .glyphicon-heart.heart-full,
    .fav-remove-button {
        display: none;
    }

    .favorite-button.btn {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.d4-preview .YouSavePercentLabel {
    z-index: 22;
    position: absolute;
    top: 5px;
    left: 5px;
    font-weight: bold;
    background: #e6b74b;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: none;
    font-size: 16px;
    text-align: center;
    padding-top: 14px;
}

.d4-preview {
    .AddAttributeIconContainer,
    .att-icons-container {
        position: absolute;
        top: 1px;
        right: 1px;
        z-index: 22;
    }
}



.d4-preview-container .d4-preview-item.d4-preview-item-stock .AddStockContainer {
    position: initial;
    right: initial;
    bottom: initial;

    .AddStockIcon {
        margin-right: 0.5em;
    }
}

.arrow-up {
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #2f2f2f;
}

.d4-preview-container .review-score {
    font-size: initial;
}

.d4-preview {
    .d4-preview-item-image {
        height: 100%;
        position: relative;
        .around_slides {
            height: 100%;

            .rsOverflow {
                height: 100%;
            }
        }
    }
}

.d4-preview.d4-preview-rhs {
    .d4-preview-container {
        width: 100%;
        display: block;
        height: auto;
    }

    .d4-preview-item-image {
        height: 474px;
        margin-right: 0px;
        width: 100%;
        max-width: 100%;
    }

    .d4-preview-item-info {
        display: block;
        height: auto;
        min-height: unset;
        max-height: unset;
        justify-content: stretch;
        margin-left: 0px;
        width: 100%;
        max-width: 100%;
    }
}
