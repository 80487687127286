﻿/* Artikkel håndtering 
-------------------------------------------------------------------------------------*/
.StandardArticleMain {
    overflow: hidden;
    border: Solid 1px #ebeae5;
    padding: 10px 10px 10px 10px;
    margin: 0px 0px 10px 0px;
    vertical-align: top;
}

#PageColumnRight .StandardArticleMain, #PageColumnLeft .StandardArticleMain {
    border: Solid 1px #D8D6CE;
    background-color: #ffffff;
}

.StandardArticleMainExBorder {
    vertical-align: top;
}

.BannerArticleMain {
    overflow: hidden;
}

.NoTopBorder {
    border-top: 0px;
}

.NoBottomBorder {
    border-bottom: 0px;
}

#PageColumnRight .NoTopBorder, #PageColumnLeft .NoTopBorder {
    border-top: 0px;
}

#PageColumnRight .NoBottomBorder, #PageColumnLeft .NoBottomBorder {
    border-bottom: 0px;
}

.SearchListArticle {
    h3.search-title {
        font-size: 16px;
        margin-bottom: 0px;
        max-width: 100%;
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }

    .ingress-summary {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        line-height: 1.3em;
        max-height: 2.4em;
        overflow: hidden;
        position: relative;

        /*&:before {
            content: '...';
            position: absolute;
            right: 0em;
            bottom: 0;
            background: white;
        }*/

        * {
            font-size: 14px;
        }

    }
}