
                    @import "@/css/ajax-rendering/admin-lte/_variables.scss";
                
@import '../publishing/mixins';


.d4-popup .modal-body #lightbox-placeholder.center-placeholder {
    margin: 10px;
    max-height: 45vh;
    overflow-y: auto;
}

/* only rules that occur after user interaction may use a larger height*/
.d4-popup.d4-popup-rule-2,
.d4-popup.d4-popup-rule-3,
.d4-popup.d4-popup-rule-6,
.d4-popup.d4-popup-rule-7,
.d4-popup.d4-popup-rule-8 {
    .modal-body #lightbox-placeholder.center-placeholder {
        max-height: 92vh;
        overflow-y: auto;
    }
}

.d4-popup-quickview .modal-lg {
    @include fromSizeLG() {
        max-width: 1000px;
        width: 1000px;
    }
}

.d4-popup.d4-popup-quickview .modal-body #lightbox-placeholder.center-placeholder {
    max-height: none;

    @include fromSizeSM() {
        height: 650px;
        max-height: 650px;
    }

    .WebPubElement {
        height: 100%;
        margin-bottom: 0px;
        min-height: 490px;
    }
}
.d4-popup .modal-body #lightbox-placeholder.center-placeholder .container{
    max-width:862px;
}

div.popup-field {
    display:none;
}

.tiny-popup div.popup-field, #rhs-popup-sidebar div.popup-field, 
div.modal.d4-popup .center-placeholder .web-pub-field.popup-field {
    display: block;
}
